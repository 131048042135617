





































import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  CodeReportDetailType,
  OrderReportsListItemType,
} from '@/types/orderReports'
import { filesAPI } from '@/api/files.api'
import { downloadBinaryFile } from '@/utils/files'

@Component
export default class OrderReportsDetail extends Vue {
  @Prop({ type: Object })
  report!: OrderReportsListItemType | CodeReportDetailType

  @Prop({ type: String, default: 'orders' })
  variant?: 'orders' | 'codes'

  reportTypes = {
    FULL: 'Полный',
    ORDER: 'Заказы',
    ACCRUAL: 'Начисления',
    ORDER_P: 'Партнерский счет',
  }

  reportsStatus = {
    finished: 'Готов',
    new: 'Новый',
    in_progress: 'В процессе',
  }

  async handleDownloadReport(id: string): Promise<void> {
    const [_, response] = await filesAPI.download(id)

    if (response) {
      downloadBinaryFile(response.data, response.headers)
    }
  }

  showMemberInfo() {
    this.$emit(
      'show-member-info',
      (this.report as OrderReportsListItemType).VENDOR_ID
    )
  }
}
