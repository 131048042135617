var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"orders-table",attrs:{"data":_vm.reports},on:{"row-click":_vm.handleShowReportInfo,"sort-change":_vm.handleChangeSort}},[_c('el-table-column',{attrs:{"sortable":"custom","prop":"ID","label":"Id","min-width":"90","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"ID","label":"Id","inputLabel":"Id"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])}),(_vm.userRole !== 'user')?_c('el-table-column',{attrs:{"sortable":"custom","prop":"USER_LOGIN","label":"Участник","min-width":"130","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"USER_LOGIN","label":"Участник","inputLabel":"Участник"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"link"},[_vm._v(" "+_vm._s(row.USER_LOGIN)+" ")])]}}],null,false,3955037268)}):_c('el-table-column',{attrs:{"sortable":"custom","prop":"USER_LOGIN","label":"Продавец","min-width":"130","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"USER_LOGIN","label":"Продавец","inputLabel":"Продавец"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"link"},[_vm._v(" "+_vm._s(row.USER_LOGIN)+" ")])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"DATE","label":_vm.variant === 'orders' ? 'Дата создания' : 'Дата формирования',"min-width":"130","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"date","columnName":"DATE","label":_vm.variant === 'orders' ? 'Дата заказа' : 'Дата формирования',"inputLabel":_vm.variant === 'orders' ? 'Дата заказа' : 'Дата формирования'},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])}),_c('el-table-column',{attrs:{"prop":"REPORT_NAME","label":"Отчет","min-width":"130","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"orders-table__download link",on:{"click":function($event){$event.preventDefault();return _vm.handleDownloadReport(row.FILE_ID)}}},[_vm._v(" "+_vm._s(row.REPORT_NAME)+" ")])]}}])}),(_vm.isShowVendorColumn)?_c('el-table-column',{attrs:{"sortable":"custom","prop":"VENDOR_NAME","label":"Вендор","min-width":"130","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"VENDOR_NAME","label":"Вендор","inputLabel":"Вендор"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}],null,false,3464339491)}):_vm._e(),(_vm.variant === 'orders')?_c('el-table-column',{attrs:{"sortable":"custom","prop":"REPORT_TYPE","label":"Тип","min-width":"130","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"select","columnName":"REPORT_TYPE","label":"Тип","inputLabel":"Тип","options":_vm.typeOptions},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.reportTypes[row.REPORT_TYPE]))])]}}],null,false,1829580865)}):_vm._e(),_c('el-table-column',{attrs:{"sortable":"custom","prop":"STATUS","label":"Статус","min-width":"130","label-class-name":"color-gray"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"select","columnName":"STATUS","label":"Статус","inputLabel":"Статус","options":_vm.statusOptions},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.reportsStatus[row.STATUS]))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }